import "./App.css";
import Navbar from "./Nav";
import About from "./About";
import Terms from "./Terms";
import Contact from "./Contact";
import Join from "./Join";
import getOnPlayStore from "./get-app-on-playstore-logo.png"
import getOnAppStore from './get-App-On-Appstore.png'

function Footer() {
  return (
    <footer className="footer">
      <div className="containerFooter">
        <p className="text-muted">&copy; GistGate Media 2023. All Rights Reserved.</p>
      </div>
    </footer>
  );
}

function Hero() {
  return (
    <div className="heroContainer" >
      <h1 className="welcome">
        Welcome to <br></br>GistGate News App
      </h1>
      <p className="subhead">The only news app you will ever need!</p>
      <div className="linkToStoresContainer">
      <a href="https://play.google.com/store/apps/details?id=com.anatugreen.GistGate" target="_blank" >
        <img src={getOnPlayStore} alt="Get GistGate NewsApp on Playstore" />
      </a>
      <a href="#" target="_blank" style={{marginLeft: 10}} >
        <img src={getOnAppStore} alt="Get GistGate NewsApp on AppStore" />
      </a>
      </div>
    </div>
  );
}

function App() {

  return (
    <div className="App">
      <div className="heroSection" id="section1">
        <Navbar />
        <Hero />
      </div>

      <div id="section2">
        <About />
      </div>
      <div id="section5">
        <Join />
      </div>
      <div id="section4">
        <Contact />
      </div>
      <div id="section3">
        <Terms />
      </div>
      <Footer />
    </div>
  );
}



// const overlayStyles = {
//   content: '""',
//   display: "block",
//   position: "absolute",
//   top: 0,
//   left: 0,
//   width: "100%",
//   height: "100%",
//   backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the alpha value to set the transparency of the overlay color
// };

export default App;
